<template>
  <v-container fluid class="grey lighten-4" style="height: 100%">
    <v-row justify="center">
      <v-col>
        <v-card-title> Ustawienia konta </v-card-title>

        <v-row align="center" justify="center">
          <v-col cols="8" sm="8" md="6" class="py-0">
            <v-form v-model="validPassword" lazy="true">
              <v-col cols="12">
                <h3>Zmiana hasła</h3>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-alert v-if="message.password" type="error">
                  {{ message.password }}
                </v-alert>
                <v-alert v-else-if="success.password" type="success">
                  {{ success.password }}
                </v-alert>
              </v-col>

              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="change_password_data.pass"
                  label="Nowe hasło"
                  :rules="passwordRule"
                  type="password"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="change_password_data.repass"
                  label="Powtórz nowe hasło"
                  :rules="passwordRule"
                  type="password"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="change_password_data.oldpass"
                  label="Aktualne hasło"
                  :rules="passwordRule"
                  type="password"
                ></v-text-field>
              </v-col>

              <v-row align="center" justify="center">
                <v-col cols="3">
                  <v-btn
                    @click="change_password()"
                    :disabled="!validPassword"
                    color="amber accent-3"
                    class="grey--text text--darken-4"
                    v-tilt
                    >Zmień hasło</v-btn
                  >
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>

        <br />
        <v-row align="center" justify="center">
          <v-col cols="8" sm="8" md="6">
            <v-form v-model="validEmail" lazy="true">
              <v-col cols="12">
                <h3>Zmiana adresu e-mail</h3>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-alert v-if="message.email" type="error">
                  {{ message.email }}
                </v-alert>
                <v-alert v-else-if="success.email" type="success">
                  {{ success.email }}
                </v-alert>
              </v-col>

              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="change_email_data.email"
                  label="Nowy e-mail"
                  :rules="emailRule"
                  type="email"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="change_email_data.pass"
                  label="Aktualne hasło"
                  :rules="passwordRule"
                  type="password"
                ></v-text-field>
              </v-col>

              <v-row align="center" justify="center">
                <v-col cols="3">
                  <v-btn
                    @click="change_email()"
                    :disabled="!validEmail"
                    color="amber accent-3"
                    class="grey--text text--darken-4"
                    v-tilt
                    >Zmień email</v-btn
                  >
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import axios from 'axios';
  export default {
    data: () => ({
      passwordRule: [
        v => !!v || 'Pole jest wymagane',
        v =>
          /^(?:(?=.*\d)(?=.*[A-Z])(?=.*[a-z])|(?=.*\d)(?=.*[^A-Za-z0-9])(?=.*[a-z])|(?=.*[^A-Za-z0-9])(?=.*[A-Z])(?=.*[a-z])|(?=.*\d)(?=.*[A-Z])(?=.*[^A-Za-z0-9]))(?!.*(.)\1{2,})[A-Za-z0-9!~<>,;:_=?*+#."&§%°()\|\[\]\-\$\^\@\/]{8,32}$/.test(
            v
          ) ||
          'Musi zawierać: 8-32 znaki, 1 wielką literę, 1 małą literę, 1 numer lub znak specjalny. Nie może zawierać dwóch identycznych znaków z rzędu.',
      ],
      emailRule: [
        v => !!v || 'Pole jest wymagane',
        v =>
          /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(
            v
          ) || 'Wprowadzono email ma niepoprawny format',
      ],
      validPassword: false,
      validEmail: false,
      message: {
        password: null,
        email: null,
      },
      success: {
        password: null,
        email: null,
      },
      change_password_data: {
        pass: null,
        repass: null,
        oldpass: null,
      },
      change_email_data: {
        email: null,
        pass: null,
      },
    }),
    methods: {
      change_password: function () {
        axios({
          url: this.$store.state.url + '/api/customer/change_password',
          data: {
            token: this.$store.state.token,
            data: this.change_password_data,
          },
          method: 'POST',
        })
          .then(resp => {
            if (resp.data.status === 200) {
              this.success.password = resp.data.message;
              this.message.password = null;
              setTimeout(() => {
                window.location.reload();
              }, 300);
            } else {
              this.message.password = resp.data.message;
              this.success.password = null;
            }
          })
          .catch();
      },
      change_email: function () {
        axios({
          url: this.$store.state.url + '/api/customer/change_email',
          data: {
            token: this.$store.state.token,
            data: this.change_email_data,
          },
          method: 'POST',
        })
          .then(resp => {
            if (resp.data.status === 200) {
              this.success.email = resp.data.message;
              this.message.email = null;
              setTimeout(() => {
                window.location.reload();
              }, 300);
            } else {
              this.message.email = resp.data.message;
              this.success.email = null;
            }
          })
          .catch();
      },
    },
  };
</script>
